import { FC, useRef } from 'react';
import { Container, Grid, Typography, Box } from '@mui/material';
import { leToBr } from '../../utils';
import img15 from '../../assets/images/img15.jpeg';
import img16 from '../../assets/images/img16.jpeg';
import img17 from '../../assets/images/img17.jpeg';
import img18 from '../../assets/images/img18.jpeg';
import img19 from '../../assets/images/img19.png';
import img20 from '../../assets/images/img20.png';
import img21 from '../../assets/images/img21.png';
import img22 from '../../assets/images/img22.jpeg';
import img23 from '../../assets/images/img23.jpeg';
import img24 from '../../assets/images/img24.jpeg';
import img25 from '../../assets/images/img25.jpeg';
import img26 from '../../assets/images/img26.jpeg';
import img27 from '../../assets/images/img27.jpeg';
import img28 from '../../assets/images/img28.jpeg';
import img29 from '../../assets/images/img29.jpeg';
import img30 from '../../assets/images/img30.jpeg';
import img31 from '../../assets/images/img31.jpeg';
import img32 from '../../assets/images/img32.jpeg';
import img35 from '../../assets/images/img35.jpeg';
import img36 from '../../assets/images/img36.jpeg';
import img37 from '../../assets/images/img37.jpeg';
import { useTranslation } from 'react-i18next';
import InView from '../common/InView';

const EquipmentSection: FC<SectionProps> = ({ onEnter }) => {
  const equipmentRef = useRef<HTMLElement>(null);
  const { t } = useTranslation();

  return (
    <>
      <InView targetRef={equipmentRef} onEnter={() => onEnter?.('equipment')}>
        <Container
          ref={equipmentRef}
          id="equipment"
          component="section"
          maxWidth={false}
          sx={{ maxWidth: 1380, mb: 7.5 }}
        >
          <Box
            component="section"
            sx={{
              backgroundImage: `url(${img16})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              minHeight: 300,
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
              zIndex: 1,
              mb: 2,
              pb: 5,
              '&:before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: '60%',
                backgroundColor: 'rgba(25, 25, 25, 0.39)',
                background:
                  'linear-gradient(180deg, rgba(25,25,25,0.39) 0%, rgba(25,25,25,0.39) 50%, rgba(25,25,25,0) 100%)',
                zIndex: -1,
              },
            }}
            height={{ lg: '100vh', md: '50vh', sx: 300 }}
            paddingTop={{ md: 5, xs: 2 }}
            marginTop={{ md: 10, xs: 2 }}
          >
            <Typography
              variant="h4"
              sx={{
                fontWeight: 'bold',
                mb: 2,
                textAlign: 'center',
              }}
              fontSize={{ md: 80, xs: 40 }}
            >
              {t('home.equipment-for-rent')}
            </Typography>
          </Box>

          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold' }}>
                {leToBr(t('home.images'))}
              </Typography>
            </Grid>

            <Grid container xs={12} justifyContent="center" spacing={1}>
              <Grid item xs={4}>
                <img
                  className="img-fluid h-100 object-fit-cover"
                  src={img15}
                  alt=""
                />
              </Grid>
              <Grid item xs={4}>
                <img
                  className="img-fluid h-100 object-fit-cover"
                  src={img17}
                  alt=""
                />
              </Grid>
            </Grid>

            <Grid item xs={12} sx={{ mt: 2 }}>
              <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold' }}>
                {leToBr(t('home.sound'))}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <img
                className="img-fluid h-100 object-fit-cover"
                src={img18}
                alt=""
              />
            </Grid>
            <Grid item xs={4}>
              <img
                className="img-fluid h-100 object-fit-cover"
                src={img19}
                alt=""
              />
            </Grid>
            <Grid item xs={4}>
              <img
                className="img-fluid h-100 object-fit-cover"
                src={img20}
                alt=""
              />
            </Grid>

            <Grid item xs={12} sx={{ mt: 2 }}>
              <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold' }}>
                {leToBr(t('home.ligths'))}
              </Typography>
            </Grid>

            <Grid container xs={12} justifyContent="center" spacing={1}>
              <Grid item xs={4}>
                <img
                  className="img-fluid h-100 object-fit-cover"
                  src={img21}
                  alt=""
                />
              </Grid>
              <Grid item xs={2}>
                <img
                  className="img-fluid h-100 object-fit-cover"
                  src={img22}
                  alt=""
                />
              </Grid>
              <Grid item xs={2}>
                <img
                  className="img-fluid h-100 object-fit-cover"
                  src={img23}
                  alt=""
                />
              </Grid>
              <Grid item xs={2}>
                <img
                  className="img-fluid h-100 object-fit-cover"
                  src={img24}
                  alt=""
                />
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <img
                className="img-fluid h-100 object-fit-cover"
                src={img25}
                alt=""
              />
            </Grid>
            <Grid item xs={6}>
              <img
                className="img-fluid h-100 object-fit-cover"
                src={img26}
                alt=""
              />
            </Grid>
            <Grid item xs={6}>
              <img
                className="img-fluid h-100 object-fit-cover"
                src={img27}
                alt=""
              />
            </Grid>
            <Grid item xs={6}>
              <img
                className="img-fluid h-100 object-fit-cover"
                src={img28}
                alt=""
              />
            </Grid>

            <Grid item xs={12} sx={{ mt: 2 }}>
              <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold' }}>
                {leToBr(t('home.miscellaneous'))}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <img
                className="img-fluid h-100 object-fit-cover"
                src={img29}
                alt=""
              />
            </Grid>
            <Grid item xs={3}>
              <img
                className="img-fluid h-100 object-fit-cover"
                src={img30}
                alt=""
              />
            </Grid>
            <Grid item xs={3}>
              <img
                className="img-fluid h-100 object-fit-cover"
                src={img31}
                alt=""
              />
            </Grid>
            <Grid item xs={3}>
              <img
                className="img-fluid h-100 object-fit-cover"
                src={img32}
                alt=""
              />
            </Grid>
          </Grid>
        </Container>
      </InView>

      <Container
        component="section"
        maxWidth={false}
        sx={{ maxWidth: 1380, mb: 7.5, mt: 10 }}
      >
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <img
              className="img-fluid h-100 object-fit-cover"
              src={img36}
              alt=""
            />
          </Grid>
          <Grid item xs={4}>
            <img
              className="img-fluid h-100 object-fit-cover"
              src={img37}
              alt=""
            />
          </Grid>
          <Grid item xs={4}>
            <img
              className="img-fluid h-100 object-fit-cover"
              src={img35}
              alt=""
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default EquipmentSection;
