import { FC, useRef } from 'react';
import { Container, Grid, Stack, Typography, Box } from '@mui/material';
import ReactPlayer from 'react-player/lazy';
import img1 from '../../assets/images/img1.jpeg';
import img2 from '../../assets/images/img2.jpeg';
import img4 from '../../assets/images/img4.jpeg';
import img7 from '../../assets/images/img7.jpeg';
import img8 from '../../assets/images/img8.jpeg';
import img9 from '../../assets/images/img9.jpeg';
import img10 from '../../assets/images/img10.jpeg';
import img11 from '../../assets/images/img11.jpeg';
import img12 from '../../assets/images/img12.jpeg';
import img13 from '../../assets/images/img13.jpeg';
import img15 from '../../assets/images/our-work.jpeg';
import { leToBr } from '../../utils';
import { useTranslation } from 'react-i18next';
import InView from '../common/InView';

const WorkSection: FC<SectionProps> = ({ onEnter }) => {
  const ourWorkRef = useRef<HTMLElement>(null);
  const { t } = useTranslation();

  return (
    <InView targetRef={ourWorkRef} onEnter={() => onEnter?.('our-work')}>
      <Container
        ref={ourWorkRef}
        id="our-work"
        component="section"
        maxWidth={false}
        sx={{ mb: 14, maxWidth: 1390 }}
      >
        <Box
          component="section"
          sx={{
            backgroundImage: `url(${img15})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            minHeight: 300,
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            zIndex: 1,
            mb: 2,
            pb: 5,
            '&:before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: '60%',
              backgroundColor: 'rgba(25, 25, 25, 0.39)',
              background:
                'linear-gradient(180deg, rgba(25,25,25,0.39) 0%, rgba(25,25,25,0.39) 50%, rgba(25,25,25,0) 100%)',
              zIndex: -1,
            },
          }}
          height={{ lg: '100vh', md: '50vh', sx: 300 }}
          paddingTop={{ md: 5, xs: 2 }}
          marginTop={{ md: 10, xs: 3 }}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: 'bold',
              mb: 2,
              textAlign: 'center',
            }}
            fontSize={{ md: 80, xs: 40 }}
          >
            {t('home.our-work.title')}
          </Typography>
        </Box>

        <Stack
          direction="column"
          sx={{
            '& img': {
              display: 'block',
              width: '100%',
            },
          }}
        >
          <Box
            id="local-producer"
            component="section"
            sx={{
              backgroundImage: `url(${img1})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPositionX: '30%',
              minHeight: 667,
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
              zIndex: 1,
              mb: 1,
              pb: 5,
              '&:before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: '30%',
                backgroundColor: 'rgba(25, 25, 25, 0.39)',
                background:
                  'linear-gradient(180deg, rgba(25,25,25,0.39) 0%, rgba(25,25,25,0.39) 50%, rgba(25,25,25,0) 100%)',
                zIndex: -1,
              },
            }}
            height={{ xs: 'auto' }}
            paddingTop={{ md: 5, xs: 2 }}
            paddingLeft={{ md: 5, xs: 2 }}
          >
            <Typography variant="h4" sx={{ mb: 6, fontWeight: 'bold' }}>
              {leToBr(t('home.our-work.as-producer-for-local-client.title'))}
            </Typography>
            <Typography
              component={Typography}
              marginLeft={{ md: '50%', xs: 0 }}
            >
              {leToBr(
                t('home.our-work.as-producer-for-local-client.description')
              )}
            </Typography>
          </Box>
          <Grid container spacing={1}>
            <Grid item mb={5} xs={12}>
              <img
                className="img-fluid h-100 object-fit-cover"
                src={img2}
                alt=""
              />
            </Grid>
          </Grid>

          {/* <Grid container spacing={{ md: 20, xs: 4 }} sx={{ mb: 8 }}>
            <Grid item xs={12} lg={7}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <img
                    className="img-fluid h-100 object-fit-cover"
                    src={img1}
                    alt=""
                  />
                </Grid>
                <Grid item xs={12}>
                  <img
                    className="img-fluid h-100 object-fit-cover"
                    src={img2}
                    alt=""
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={5}>
              <Typography>
                {leToBr(
                  t('home.our-work.as-producer-for-local-client.description')
                )}
              </Typography>
            </Grid>
          </Grid> */}

          <Grid
            container
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mb: 5,
            }}
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {[
              'https://youtu.be/75Xwsj14ip8',
              'https://youtu.be/FkXnbtDFJzk',
              'https://youtu.be/DQtzN6jMyGQ',
              'https://youtu.be/-9ra7mq7wBM',
            ].map((url, index) => (
              <Grid item xs={12} sm={3} md={3} key={index}>
                <ReactPlayer url={url} controls width="100%" height="100%" />
              </Grid>
            ))}
          </Grid>

          <Box
            id="fixer"
            component="section"
            sx={{
              backgroundImage: `url(${img11})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              minHeight: 667,
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
              zIndex: 1,
              '&:before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: '50%',
                backgroundColor: 'rgba(25, 25, 25, 0.39)',
                background:
                  'linear-gradient(180deg, rgba(25,25,25,0.39) 0%, rgba(25,25,25,0.39) 50%, rgba(25,25,25,0) 100%)',
                zIndex: -1,
              },
            }}
            paddingTop={{ md: 5, xs: 2 }}
            paddingLeft={{ md: 5, xs: 2 }}
          >
            <Typography variant="h4" sx={{ mb: 6, fontWeight: 'bold' }}>
              {leToBr(
                t('home.our-work.as-local-producer-for-international-tv.title')
              )}
            </Typography>
          </Box>

          <Grid container sx={{ mb: 8 }} spacing={{ md: 0, xs: 4 }}>
            <Grid item xs={12} lg={7} mt={10}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <img
                    className="img-fluid h-100 object-fit-cover"
                    src={img8}
                    alt=""
                  />
                </Grid>
                <Grid item xs={12}>
                  <img
                    className="img-fluid h-100 object-fit-cover"
                    src={img9}
                    alt=""
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={5} marginTop={{ md: 10, xs: 2 }}>
              <Stack
                direction="column"
                justifyContent="space-between"
                alignSelf="stretch"
                spacing={2.5}
              >
                {['usa', 'united-kingdom', 'germany', 'netherlands'].map(
                  (country) => (
                    <Stack key={country} direction="column">
                      <Typography
                        variant="h5"
                        color="secondary"
                        sx={{ fontWeight: 'bold', fontFamily: 'Poppins' }}
                      >
                        {t(
                          `home.our-work.as-local-producer-for-international-tv.${country}.title`
                        )}
                      </Typography>
                      <Typography>
                        {leToBr(
                          t(
                            `home.our-work.as-local-producer-for-international-tv.${country}.description`
                          )
                        )}
                      </Typography>
                    </Stack>
                  )
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} lg={5}>
              <Stack
                direction="column"
                justifyContent="space-between"
                alignSelf="stretch"
                spacing={2.5}
              >
                {[
                  'south-africa',
                  'canada',
                  'australia',
                  'france',
                  'sweden',
                  'qatar',
                  'abu-dhabi',
                  'italy',
                  'russia',
                  'malaysia',
                  'brazil',
                  'poland',
                  'spain',
                ].map((country) => (
                  <Stack key={country} direction="column">
                    <Typography
                      variant="h5"
                      color="secondary"
                      sx={{ fontWeight: 'bold', fontFamily: 'Poppins' }}
                    >
                      {t(
                        `home.our-work.as-local-producer-for-international-tv.${country}.title`
                      )}
                    </Typography>
                    <Typography>
                      {leToBr(
                        t(
                          `home.our-work.as-local-producer-for-international-tv.${country}.description`
                        )
                      )}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            </Grid>

            <Grid item xs={12} lg={7}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <img
                    className="img-fluid h-100 object-fit-cover"
                    src={img4}
                    alt=""
                  />
                </Grid>
                <Grid item xs={12}>
                  <img
                    className="img-fluid h-100 object-fit-cover"
                    src={img7}
                    alt=""
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container sx={{ mb: 8 }} spacing={{ md: 0, xs: 1 }}>
            <Grid item xs={4}>
              <img
                className="img-fluid h-100 object-fit-cover"
                src={img10}
                alt=""
              />
            </Grid>
            <Grid item xs={4}>
              <img
                className="img-fluid h-100 object-fit-cover"
                src={img13}
                alt=""
              />
            </Grid>
            <Grid item xs={4}>
              <img
                className="img-fluid h-100 object-fit-cover"
                src={img12}
                alt=""
              />
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {[
              'https://youtu.be/1bD-fhNkgdo',
              'https://youtu.be/t0yzCvcZeQw',
              'https://youtu.be/snH09fWQ_kE',
            ].map((url, index) => (
              <Grid item xs={12} sm={3} md={3} key={index}>
                <ReactPlayer url={url} controls width="100%" height="100%" />
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Container>
    </InView>
  );
};

export default WorkSection;
