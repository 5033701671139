import { FC } from 'react';
import { Mail as MailIcon } from '@mui/icons-material';
import { Box, IconButton, Stack, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { copyTextToClipboard } from '../../utils/clipboard';

const Footer: FC = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      <Stack
        component="section"
        direction="row"
        justifyContent="center"
        sx={{ my: 8 }}
      >
        <Tooltip title={process.env.REACT_APP_CONTACT_EMAIL}>
          <IconButton
            onClick={() =>
              copyTextToClipboard(process.env.REACT_APP_CONTACT_EMAIL).then(
                () =>
                  enqueueSnackbar(t('home.contact.email-copied-to-clipboard'), {
                    variant: 'success',
                  })
              )
            }
            sx={{
              p: 2,
              backgroundColor: 'white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '50% 50% 0 50%',
              transform: 'rotate(45deg)',
            }}
          >
            <MailIcon
              htmlColor="#0095B4"
              sx={{ transform: 'rotate(-45deg)' }}
            />
          </IconButton>
        </Tooltip>
      </Stack>

      <Box
        component="footer"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          py: 2.25,
        }}
      >
        Copyright © - All Right Reserved - cineroutes
      </Box>
    </>
  );
};

export default Footer;
