import { FC, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import bannerImg from '../../assets/images/banner.jpeg';
import { leToBr } from '../../utils';
import { useTranslation } from 'react-i18next';
import InView from '../common/InView';

const HomeSection: FC<SectionProps> = ({ onEnter }) => {
  const homeRef = useRef<HTMLElement>(null);
  const { t } = useTranslation();

  return (
    <>
      <InView targetRef={homeRef} onEnter={() => onEnter?.('home')}>
        <Box
          ref={homeRef}
          id="home"
          component="section"
          sx={{
            backgroundImage: `url(${bannerImg})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            minHeight: 667,
            display: 'flex',
            flexDirection: 'column',
            pt: 16,
            position: 'relative',
            zIndex: 1,
            '&:before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: '50%',
              backgroundColor: 'rgba(25, 25, 25, 0.39)',
              background:
                'linear-gradient(180deg, rgba(25,25,25,0.39) 0%, rgba(25,25,25,0.3) 50%, rgba(25,25,25,0) 100%)',
              zIndex: -1,
            },
          }}
          height={{ md: '100vh', xs: 'auto' }}
          paddingBottom={{ xs: 5 }}
        >
          <Box
            sx={(theme) => ({
              [theme.breakpoints.down('lg')]: {
                p: 2,
              },
            })}
          >
            <Box
              sx={(theme) => ({
                width: 'clamp(0px, 800px, 90%)',
                mt: 15,
                backgroundColor: `rgba(25, 25, 25, 0.5)`,
                position: 'absolute',
                left: '50%',
                top: theme.spacing(3),
                p: 6.25,
                transform: 'translateX(-50%)',
                [theme.breakpoints.down('lg')]: {
                  position: 'static',
                  width: '100%',
                  px: 4,
                  transform: 'none',
                  mt: 5,
                },
              })}
            >
              <Typography
                variant="h2"
                sx={(theme) => ({
                  fontWeight: 'bold',
                  mb: 2,
                  mt: -12,
                  textTransform: 'uppercase',
                  [theme.breakpoints.down('md')]: {
                    mt: -10,
                  },
                })}
              >
                {t('home.title')}
              </Typography>
              <Typography>{leToBr(t('home.heading'))}</Typography>
            </Box>
          </Box>
        </Box>
      </InView>
    </>
  );
};

export default HomeSection;
