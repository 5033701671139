import { FC, useCallback } from 'react';
import HomeSection from '../components/partials/HomeSection';
import WorkSection from '../components/partials/WorkSection';
import EquipmentSection from '../components/partials/EquipmentSection';
import ContactSection from '../components/partials/ContactSection';
import Footer from '../components/common/Footer';

export interface HomePageProps {
  activeSectionId?: string;
  setActiveSectionId?: (activeSectionId: string) => void;
  scrolling: boolean;
}

const HomePage: FC<HomePageProps> = ({
  activeSectionId,
  setActiveSectionId,
  scrolling,
}) => {
  const onEnter = useCallback(
    (id: string) => {
      if (!scrolling && id !== activeSectionId) {
        setActiveSectionId?.(id);
      }
    },
    [activeSectionId, scrolling, setActiveSectionId]
  );

  return (
    <>
      <HomeSection {...{ onEnter }} />
      <WorkSection {...{ onEnter }} />
      <EquipmentSection {...{ onEnter }} />
      <ContactSection {...{ onEnter }} />
      <Footer />
    </>
  );
};

export default HomePage;
