import { FC } from 'react';
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Stack,
  TextField,
} from '@mui/material';
import img39 from '../../assets/images/img39.jpeg';
import SendIcon from '../../components/icons/Send';
import GlowingTitle from '../common/GlowingTitle';
import emailjs from '@emailjs/browser';
import { useTranslation } from 'react-i18next';

const sendMail = (e: any) => {
  e.preventDefault();

  emailjs
    .sendForm(
      'service_yli3szk',
      'template_1a6zr6r',
      e.target,
      'xEh7zVU5TZIx8hIoL'
    )
    .then(
      (result: any) => {
        console.log(result.text);
      },
      (error: any) => {
        console.log(error.text);
      }
    );
  e.target.reset();
};

const ContactSection: FC<SectionProps> = () => {
  const { t } = useTranslation();

  return (
    <Container
      id="contact"
      component="section"
      maxWidth="xl"
      sx={(theme) => ({
        my: 20,
        [theme.breakpoints.down('md')]: {
          mb: 8,
          mt: 12,
        },
      })}
    >
      <Grid container direction="row" spacing={{ lg: 16, md: 8, xs: 4 }}>
        <Grid item xs={12} md={6}>
          <img
            className="img-fluid h-100 object-fit-cover"
            src={img39}
            alt=""
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            id="contact"
            component="section"
            sx={{
              minHeight: 572,
              position: 'relative',
              zIndex: 1,
              '&:before': {
                zIndex: -1,
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: 572,
                background:
                  'linear-gradient(to bottom, rgba(25, 25, 25, 1) 0%, rgba(25, 25, 25, 0) 35%, rgba(25, 25, 25, 0) 65%, rgba(25, 25, 25, 1) 100%)',
              },
              '&:after': {
                zIndex: -1,
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: 572,
                backgroundColor: 'rgba(25, 25, 25, 0.7)',
              },
            }}
          >
            <Container maxWidth={false} sx={{ maxWidth: 825, py: 10 }}>
              <GlowingTitle
                sx={{ fontFamily: 'Poppins', fontWeight: 'bold' }}
                glowingBackgroundSx={{
                  width: 100,
                  height: 101,
                  opacity: 0.5,
                  left: '35%',
                  top: '60%',
                }}
              >
                {t('home.contact.title')}
              </GlowingTitle>
              <Divider
                sx={{
                  borderWidth: 3,
                  borderColor: 'white',
                  width: 300,
                  mb: 4,
                }}
              />
              <Stack
                component="form"
                direction="column"
                alignItems="flex-start"
                spacing={2}
                onSubmit={sendMail}
              >
                <TextField
                  fullWidth
                  label={t('home.contact.name')}
                  variant="standard"
                  name="name"
                />
                <TextField
                  fullWidth
                  label={t('home.contact.email')}
                  variant="standard"
                  name="email"
                />
                <TextField
                  fullWidth
                  label={t('home.contact.phone-number')}
                  variant="standard"
                  name="number"
                />
                <TextField
                  multiline
                  fullWidth
                  rows={5}
                  label={t('home.contact.message')}
                  variant="standard"
                  name="message"
                />

                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  startIcon={<SendIcon />}
                  size="large"
                  sx={{ textTransform: 'none', mt: 3 }}
                >
                  {t('home.contact.send')}
                </Button>
              </Stack>
            </Container>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ContactSection;
