import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    background: {
      default: '#191919',
    },
    primary: {
      main: '#0095B4',
    },
    secondary: {
      main: '#FF9E1E',
    },
    mode: 'dark',
  },
  typography: {
    fontFamily: 'Poppins',
    h1: {
      fontFamily: 'Playfair Display',
    },
    h2: {
      fontFamily: 'Playfair Display',
    },
    h3: {
      fontFamily: 'Playfair Display',
    },
    h4: {
      fontFamily: 'Playfair Display',
    },
    h5: {
      fontFamily: 'Playfair Display',
    },
    h6: {
      fontFamily: 'Playfair Display',
    },
  },
});

theme.typography.body1 = {
  ...theme.typography.body1,
  fontSize: theme.typography.pxToRem(14),
};

export default theme;
