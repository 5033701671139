import { ReactNode, Fragment } from 'react';

export function leToBr(value: string): ReactNode {
  return value.split('\n').map((line, i, a) => (
    <Fragment key={i}>
      {line}
      {i < a.length - 1 ? <br /> : null}
    </Fragment>
  ));
}
