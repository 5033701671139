import React, { FC, useCallback, useRef, useState } from 'react';
import {
  AppBar,
  Box,
  Button,
  Hidden,
  IconButton,
  lighten,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  SwipeableDrawer,
  Tab as MuiTab,
  Tabs,
  Toolbar,
  useScrollTrigger,
} from '@mui/material';
import logo from '../../assets/images/logo.png';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { scrollTo } from '../../utils/window';
import {
  Construction as ConstructionIcon,
  Home as HomeIcon,
  Landscape as LandscapeIcon,
  Menu as MenuIcon,
  Public as PublicIcon,
  Work as WorkIcon,
} from '@mui/icons-material';

const links: SectionLink[] = [
  {
    key: 'home',
    label: 'navbar.home',
    icon: <HomeIcon />,
  },
  {
    key: 'our-work',
    label: 'navbar.our-work',
    icon: <WorkIcon />,
    subLinks: [
      {
        key: 'local-producer',
        label: 'navbar.local-producer',
        icon: <LandscapeIcon />,
      },
      {
        key: 'fixer',
        label: 'navbar.fixer',
        icon: <PublicIcon />,
      },
    ],
  },
  {
    key: 'equipment',
    label: 'navbar.equipment',
    icon: <ConstructionIcon />,
  },
];

export interface NavBarProps {
  setScrolling: (scrolling: boolean) => void;
  onActiveSectionChange: (sectionId: string) => void;
  activeSectionId: string;
}

const NavBar: FC<NavBarProps> = ({
  setScrolling,
  onActiveSectionChange,
  activeSectionId,
}) => {
  const { t } = useTranslation();
  const appBarRef = useRef<HTMLDivElement>(null);
  const trigger = useScrollTrigger({
    disableHysteresis: true,
  });
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const toggleDrawer = useCallback(() => setOpenDrawer((v) => !v), []);
  const scrollToSection = useCallback(
    async (sectionId: string) => {
      const targetElement = document.getElementById(sectionId);
      if (targetElement) {
        setScrolling(true);
        await scrollTo({
          top: targetElement.offsetTop - (appBarRef.current?.offsetHeight || 0),
          behavior: 'smooth',
        });
        setScrolling(false);
      }
    },
    [appBarRef, setScrolling]
  );

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      ref={appBarRef}
      position="fixed"
      color={trigger ? 'primary' : 'transparent'}
      elevation={trigger ? 2 : 0}
      sx={{
        transition: 'all .250s ease-out',
      }}
    >
      <Toolbar
        sx={(theme) => ({
          py: 3.5,
          [theme.breakpoints.up('lg')]: {
            px: 12.5,
          },
        })}
      >
        <Link
          component={RouterLink}
          to="/"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <img src={logo} alt="Cinéroutes" />
        </Link>

        <Stack
          direction="row"
          spacing={3}
          sx={(theme) => ({
            ml: 'auto',
            [theme.breakpoints.down('md')]: {
              display: 'none',
            },
          })}
        >
          <Tabs
            value={activeSectionId}
            onChange={(_, v) => onActiveSectionChange(v)}
            sx={{
              '& .MuiTabs-flexContainer': {
                height: '100%',
                alignItems: 'center',
              },
              '& .MuiTabs-indicator': {
                height: 4,
              },
            }}
          >
            {links.map(({ key, label, subLinks }) => {
              return (
                <div>
                  <MuiTab
                    value={key}
                    sx={(theme) => ({
                      position: 'relative',
                      textDecoration: 'none',
                      color: 'white',
                      fontSize: theme.typography.pxToRem(18),
                      display: 'flex',
                      alignItems: 'center',
                      '&.Mui-selected': {
                        color: 'white',
                      },
                    })}
                    onClick={subLinks ? handleMenu : () => scrollToSection(key)}
                    label={t(label)}
                  />
                  {subLinks && (
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      {subLinks.map(({ key, label }) => {
                        return (
                          <MenuItem
                            key={key}
                            onClick={() => scrollToSection(key)}
                          >
                            {t(label)}
                          </MenuItem>
                        );
                      })}
                    </Menu>
                  )}
                </div>
              );
            })}
          </Tabs>
        </Stack>

        <Button
          component="a"
          variant="contained"
          href="#contact"
          onClick={(e: any) => {
            e.preventDefault();
            scrollToSection('contact');
          }}
          sx={(theme) => ({
            display: 'flex',
            ml: 4,
            alignItems: 'center',
            borderRadius: 2.5,
            textTransform: 'none',
            backgroundColor: 'white',
            color: theme.palette.primary.main,
            fontSize: theme.typography.pxToRem(20),
            py: 2,
            px: 6.25,
            fontWeight: 'bold',
            [theme.breakpoints.down('md')]: {
              ml: 'auto',
              py: 1,
              px: 2,
              fontSize: theme.typography.pxToRem(16),
            },
            '&:hover': {
              background: lighten(theme.palette.primary.main, 0.5),
            },
          })}
        >
          {t('navbar.contact')}
        </Button>

        <Hidden mdUp>
          <IconButton sx={{ ml: 2 }} onClick={toggleDrawer}>
            <MenuIcon sx={{ fontSize: '2rem' }} />
          </IconButton>

          <SwipeableDrawer
            anchor="right"
            open={openDrawer}
            onClose={() => setOpenDrawer(false)}
            onOpen={() => setOpenDrawer(true)}
          >
            <Box
              sx={{
                width: 'clamp(0px, 75vw, 300px)',
              }}
              role="presentation"
              onClick={() => setOpenDrawer(false)}
              onKeyDown={() => setOpenDrawer(false)}
            >
              <List>
                {links.map(({ key, label, icon }) => (
                  <ListItem
                    key={key}
                    disablePadding
                    sx={
                      activeSectionId === key
                        ? (theme) => ({
                            color: lighten(theme.palette.primary.main, 0.2),
                            position: 'relative',
                            '&:before': {
                              content: '""',
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              height: '100%',
                              width: 3,
                              backgroundColor: lighten(
                                theme.palette.primary.main,
                                0.2
                              ),
                            },
                          })
                        : undefined
                    }
                  >
                    <ListItemButton
                      onClick={() => {
                        onActiveSectionChange(key);
                        scrollToSection(key);
                        setOpenDrawer(false);
                      }}
                    >
                      <ListItemIcon sx={{ color: 'inherit' }}>
                        {icon}
                      </ListItemIcon>
                      <ListItemText primary={t(label)} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Box>
          </SwipeableDrawer>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
